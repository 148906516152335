export default {
    "meeting": {
        "addParticipant": "Қатысушыны қосу",
        "search": "Іздеу",
        "close": "Жабу",
        "new": "Жаңа",
        "online": "Онлайн",
        "ended": "Завершілді",
        "records": "Жазбалар",
        "record": "Жазба",
        "emptyList": "Тізім бос",
        "openNewWindow": "Жаңа терезеде ашу",
        "linkCopied": "Сілтеме көшірілді",
        "noData": "Деректер жоқ",
        "drawerTitle": "Кездесуді редакциялау",
        "conferenceName": "Конференция атауы",
        "description": "Сипаттама",
        "startDateTime": "Басталу күні мен уақыты",
        "duration": "Ұзақтық",
        "participants": "Қатысушылар",
        "addParticipant": "Қатысушы қосу",
        "added": "Қосылды",
        "save": "Сақтау",
        "create": "Жасау",
        "editConference": "Кездесуді редакциялау",
        "createConference": "Кездесу жасау",
        "requiredField": "Толтыру міндетті",
        "participant": "қатысушы",
        "participantGen": "қатысушыны",
        "participantsGen": "қатысушылар",
        "conferenceCreated": "Кездесу жасалды",
        "error": "Қате",
        "conferenceUpdated": "Кездесу жаңартылды",
        "warning": "Ескерту",
        "confirmEndConference": "Сіз конференцияны шын мәнінде аяқтағыңыз келе ме?",
        "cancel": "Жабу",
        "end": "Аяқтау",
        "conferenceEnded": "Конференция аяқталды",
        "conference": "Конференция",
        "confirmDeleteConference": "Сіз шын мәнінде конференцияны жойғыңыз келе ме?",
        "delete": "Жою",
        "conferenceDeleted": "Конференция жойылды",
        "connect": "Қосылу",
        "viewRecords": "Жазбаларды қарау",
        "restartConference": "Конференцияны қайта бастау",
        "endConference": "Конференцияны аяқтау",
        "inviteLink": "Шақыру сілтемесі",
        "share": "Бөлісу",
        "edit": "Редактлеу",
        "no_data": "Күні жоқ",
        "author": "Сіз авторсыз",
        "hide": "Жасыру",
        "more": "Толығырақ",
        "participants": "Қатысушылар тізімі",
        "connect": "Қосылу",
        "videos": "Бейнежазбалар",
        "author2": "Автор",
        "moderator": "Модератор",
        "not_fount": "Жиналыс табылмады немесе жойылды",
        "hour": "сағат",
        "hours": "сағат",
        "of_hours": "сағат",
        "minute": "минут",
        "minutes": "минут",
        "of_minutes": "минут"
    }
}