export default {
    "comment": {
        "showOnly": "Тек көрсету",
        "blocksLeft": "Сол жақтағы блоктар",
        "save": "Сақтау",
        "send": "Жіберу",
        "cancel": "Болдырмау",
        "addComment": "Пікір қосу",
        "updated": "Пікір жаңартылды",
        "added": "Пікір қосылды",
        "error": "Қате",
        "updated": "Өзгертілді",
        "reply": "Жауап беру",
        "edit": "Өңдеу",
        "open": "Пікірді ашу",
        "add_task": "Міндет қосу",
        "share": "Бөлісу",
        "delete": "Жою",
        "reply_to": "Пікірге жауап",
        "edit_comment": "Пікірді өңдеу",
        "reply_to_comment": "Пікірге жауап беру",
        "today": "Бүгін",
        "yesterday": "Кеше",
        "loadMore": "Тағы жүктеу ({ count })",
        "commentDeleted": "Пікір жойылды",
        "deleteError": "Жою кезінде қате",
        "reply": "Жауап беру",
        "edit": "Өзгерту",
        "openComment": "Пікірді ашу",
        "addTask": "Міндет қосу",
        "copy": "Көшіру",
        "share": "Бөлісу",
        "delete": "Жою",
        "messageCopied": "Хабарлама көшірілді",
        "copyError": "Көшіру қатесі"
    }
}